import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {NavLink, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import GoogleLogo from '../assets/images/google.png';
import NamedLogo from '../assets/images/NamedLogo.png';
import {handleSignup} from '../redux/auth/authActions';
import useAuthCheck from '../hooks/useAuth';
import {toast} from 'react-toastify';


const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useAuthCheck();

  const onSubmit = async (values, {setSubmitting}) => {
    try {
      await dispatch(handleSignup(values.email, values.password));
      navigate('/profile');
    } catch (error) {
      const errorMessage = error.message;
      toast.error(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="max-w-md w-full flex flex-col  bg-white rounded-lg px-6 pb-6">
        {/* Google Sign-Up Button */}
        <img
          alt='FrostMailLogo'
          className='h-12 self-center m-4'
          src={NamedLogo}
        ></img>
        <button
          type="button"
          className="w-full bg-gray-50 flex items-center gap-4 justify-center text-gray-600 py-2 rounded-lg mt-2 hover:bg-gray-100"
          onClick={() => toast.info('Google auth available soon!')}
        >
          <img className="h-6 w-6" src={GoogleLogo} alt="Sign Up with Google"/>
          <span>Sign up with Google</span>
        </button>

        {/* Divider */}
        <div className="flex items-center my-4">
          <hr className="flex-grow border-gray-300"/>
          <span className="mx-2 text-gray-600">OR</span>
          <hr className="flex-grow border-gray-300"/>
        </div>

        {/* Formik Form */}
        <Formik
          initialValues={{email: '', password: ''}}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({isSubmitting, errors}) => (
            <Form className="space-y-4">
              {/* Email Field */}
              <div className="flex flex-col">
                <label className="text-gray-600 text-sm mb-2" htmlFor="email">
                  Email
                </label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  className="border border-gray-300 text-sm p-2 rounded-lg"
                />
                <ErrorMessage name="email" component="div" className="text-red-500 text-sm"/>
              </div>

              {/* Password Field */}
              <div className="flex flex-col">
                <label className="text-gray-600 text-sm mb-2" htmlFor="password">
                  Create Password
                </label>
                <Field
                  name="password"
                  type="password"
                  placeholder="Create your password"
                  className="border border-gray-300 text-sm p-2 rounded-lg"
                />
                <ErrorMessage name="password" component="div" className="text-red-500 text-sm"/>
              </div>

              {/* General Error Message */}
              {errors.general && <div className="text-red-500 text-sm">{errors.general}</div>}

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-black text-white py-2 rounded-lg hover:bg-gray-800 transition-colors"
              >
                {isSubmitting ? 'Signing up...' : 'Sign Up'}
              </button>
            </Form>
          )}
        </Formik>

        {/* Login Link */}
        <div className="text-center mt-3">
          <div className="flex justify-between rounded-md transition-all text-black">
            <p>Already have an account?</p>
            <NavLink to="/login" className="text-blue-500">
              <p className="underline text-blue-700">Login</p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
