import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import ChooseTemplate from './components/ChooseTemplate';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import ProfileForm from './components/Profile';
import Signup from './pages/SignUp';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import EditTemplate from "./components/EditTemplate";

function App() {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className='App'>
        <Routes>
          {/* Public Routes */}
          <Route path='/login' element={<Login/>}/>
          <Route path='/signup' element={<Signup/>}/>

          {/* Protected Routes */}
          <Route
            path='/template'
            element={
              <ProtectedRoute>
                <ChooseTemplate/>
              </ProtectedRoute>
            }
          />
          <Route
            path='/generate'
            element={
              <ProtectedRoute>
                <Home/>
              </ProtectedRoute>
            }
          />
          <Route
            path='/profile'
            element={
              <ProtectedRoute>
                <ProfileForm/>
              </ProtectedRoute>
            }
          />
          <Route
            path='/edittemplate/:id'
            element={
              <ProtectedRoute>
                <EditTemplate/>
              </ProtectedRoute>
            }
          />

          <Route
            path='/'
            element={
              <Signup/>
            }
          />
        </Routes>
      </div>
    </Router>

  );
}

export default App;