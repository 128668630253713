/* eslint-disable */
import axiosInstance from './axiosConfig';
import {getErrorMessage} from '../utils/errorHandler';

export const uploadResume = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axiosInstance.post('/profile/file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return {success: true, data: response.data.data};
  } catch (error) {
    return {success: false, error: getErrorMessage(error)};
  }
};

export const submitProfileData = async (profileData) => {
  try {
    const response = await axiosInstance.post('/profile', profileData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return {success: true, data: response.data.data};
  } catch (error) {
    return {success: false, error: getErrorMessage(error)};
  }
};

export const fetchUserDetails = async () => {
  try {
    const response = await axiosInstance.get('/profile', {});
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const fetchUserEmail = async () => {
  try {
    const response = await axiosInstance.get('/profile/email', {});
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

