// src/components/Home.jsx
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Checkbox, Collapse, Form, Input, Modal, Select, Table, Upload} from 'antd';
import AceEditor from 'react-ace';
import Papa from 'papaparse'; // Import PapaParse
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import LogoutButton from '../components/Logout'
import '../assets/Styles/GlobalCSS.css'
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import {fetchUserTemplates, savePlaceholders, sendEmail, sendSampleEmail} from '../network/Template';
import {toast} from "react-toastify";
import {fetchUserEmail} from "../network/Profile";

const {Panel} = Collapse;

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {sentTemplateContent, sentTemplateId} = location.state || {};
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [placeholders, setPlaceholders] = useState([
    {placeholder: 'Subject', values: [''], replicate: false},
    {placeholder: 'Recipient Email', values: [''], replicate: false},
  ]);
  const [generatedTemplates, setGeneratedTemplates] = useState([]);
  const [selectedTemplateFormat, setSelectedTemplateFormat] = useState('');
  const [selectedTemplateData, setSelectedTemplateData] = useState('');
  const [templates, setTemplates] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [editForm] = Form.useForm();
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [currentViewTemplate, setCurrentViewTemplate] = useState(null);
  const [sendingStatus, setSendingStatus] = useState({});
  const [buttonState, setButtonState] = useState({
    text: 'Send Sample Mail',  // Initial button text
    loading: false,           // Initial loading state
    sent: false               // To track if the mail is sent
  });
  let [data, setData] = useState([]);

  useEffect(() => {
    if (sentTemplateId && templates?.length > 0) {
      handleTemplateChange(sentTemplateId);
    }
  }, [sentTemplateId, templates]);

  useEffect(() => {
    const fetchAndSetTemplates = async () => {
      const fetchedTemplates = await fetchTemplates();
      setTemplates(fetchedTemplates);
      if (sentTemplateContent) {
        setSelectedTemplateData(sentTemplateContent);
      }
      if (sentTemplateId) {
        setSelectedTemplateId(sentTemplateId);
      }
    };

    fetchAndSetTemplates();
  }, [sentTemplateContent, sentTemplateId]);

  const fetchTemplates = async () => {
    try {
      return await fetchUserTemplates();
    } catch (error) {
      toast.error(`Failed to fetch templates: ${error.message}`);
    }
  };

  const savePlaceholder = async () => {
    try {
      const additionalPlaceholders = placeholders.filter(
        p => !['Subject', 'Recipient Email'].includes(p.placeholder)
      );
      const sendPlaceholders = [];
      if (additionalPlaceholders.length > 0) {
        additionalPlaceholders.map((p) => sendPlaceholders.push(p.placeholder));
      }
      if (!selectedTemplateId) {
        toast.error("Select your template from the drop down");
        return;
      }
      await savePlaceholders(selectedTemplateId, sendPlaceholders);
      toast.success('Placeholders saved successfully!');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSendEmail = async (template, index) => {
    try {
      // Set sending status
      setSendingStatus((prev) => ({...prev, [index]: 'sending'}));

      await sendEmail(template);
      setSendingStatus((prev) => ({...prev, [index]: 'sent'})); // Update status to 'sent'
      toast.success(`Email ${index + 1} sent successfully!`);
    } catch (error) {
      setSendingStatus((prev) => ({...prev, [index]: 'error'})); // Handle error status if needed
      toast.error(`Failed to send Email ${index + 1}: ${error.message}`);
    }
  };

  const handleCSVUpload = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const parsedData = results.data;

        if (parsedData.length === 0) {
          toast.error('The CSV file is empty.');
          return;
        }

        // Extract headers
        const headers = results.meta.fields;

        // Validate required headers
        if (!headers.includes('Subject') || !headers.includes('Recipient Email')) {
          toast.error('CSV must include "Subject" and "Recipient Email" headers.');
          return;
        }

        // Extract placeholder headers (excluding 'Subject' and 'Recipient Email')
        const placeholderHeaders = headers.filter(
          (header) => header !== 'Subject' && header !== 'Recipient Email'
        );

        if (placeholderHeaders.length === 0) {
          toast.error('CSV must include at least one placeholder.');
          return;
        }

        // Update placeholders state
        const newPlaceholders = [
          {placeholder: 'Subject', values: []},
          {placeholder: 'Recipient Email', values: []},
          ...placeholderHeaders.map((ph) => ({placeholder: ph, values: []})),
        ];

        // Populate placeholder values
        parsedData.forEach((row) => {
          newPlaceholders.forEach((ph, index) => {
            if (index < 2) {
              // Subject and Recipient Email
              ph.values.push(row[ph.placeholder]);
            } else {
              // Other placeholders
              ph.values.push(row[ph.placeholder]);
            }
          });
        });

        setPlaceholders(newPlaceholders);

        // Re-generate the table data
        const rowsCount = newPlaceholders[0].values.length;
        const newData = Array.from({length: rowsCount}, (_, rowIndex) => ({
          key: rowIndex,
          ...newPlaceholders.reduce((acc, ph, colIndex) => {
            acc[`placeholder-${colIndex}`] = ph.values[rowIndex];
            return acc;
          }, {}),
        }));

        setData(newData);

        toast.success('CSV file uploaded and parsed successfully!');
      },
      error: function (error) {
        toast.error(`Failed to parse CSV file: ${error.message}`);
      },
    });
  };


  const handleSampleEmail = async () => {
    setButtonState({text: 'Sending', loading: true, sent: false});
    try {
      if (generatedTemplates.length > 0) {
        const UserEmail = await fetchUserEmail();
        await sendSampleEmail(generatedTemplates[0], UserEmail);
        toast.success('Mail sent to your registered email');
        setButtonState({text: 'Sent', loading: false, sent: true});
      } else {
        toast.error('No Templates Generated!')
        setButtonState({text: 'Send Sample Mail', loading: false, sent: false});
      }
    } catch (error) {
      toast.error(`Failed to send all emails: ${error.message}`);
      setButtonState({text: 'Send Sample Mail', loading: false, sent: false});
    }
  };

  const handleTemplateChange = (templateId) => {
    const chosenTemplateId = templateId;
    const chosenTemplate = templates.find((template) => template.id === chosenTemplateId);
    const defaultPlaceholders = [
      {placeholder: 'Subject', values: [''], replicate: false},
      {placeholder: 'Recipient Email', values: [''], replicate: false},
    ];
    if (chosenTemplate) {
      setSelectedTemplateId(chosenTemplate.id);
      setSelectedTemplateData(chosenTemplate.templateData);
      setSelectedTemplateFormat(chosenTemplate.templateFormat);

      const templatePlaceholders = chosenTemplate.placeholders || [];
      if (templatePlaceholders.length > 0) {
        const updatedPlaceholders = [
          ...defaultPlaceholders,
          ...templatePlaceholders.map((ph) => ({
            placeholder: ph,
            values: [''],
            replicate: false,
          })),
        ];
        setPlaceholders(updatedPlaceholders);

      } else {
        setPlaceholders(defaultPlaceholders);
      }
    } else {
      setSelectedTemplateData('');
    }
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const handlePlaceholderChange = (columnIndex, value) => {
    if (['Subject', 'Recipient Email'].includes(placeholders[columnIndex].placeholder)) {
      toast.error(`Cannot edit the required placeholder "${placeholders[columnIndex].placeholder}"`);
      return;
    }
    const newPlaceholders = [...placeholders];
    newPlaceholders[columnIndex].placeholder = value;
    setPlaceholders(newPlaceholders);
  };

  const handleValueChange = (columnIndex, rowIndex, newValue) => {
    const newPlaceholders = [...placeholders];
    if (newPlaceholders[columnIndex].replicate) {
      toast.error('Cannot edit values manually when replication is enabled.');
      return;
    }
    newPlaceholders[columnIndex].values[rowIndex] = newValue;
    setPlaceholders(newPlaceholders);
  };

  const renderTemplateContent = (text, isHTML = false) => {
    if (!text) return 'No template data available.';

    if (isHTML) {
      // Sanitize the HTML content to prevent XSS attacks
      return (text);
    } else {
      return (
        <pre style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxHeight: '500px', overflowY: 'auto'}}>
        {text}
      </pre>
      );
    }
  };

  const handleEdit = () => {
    if (selectedTemplateId) {


      if (selectedTemplateFormat === 'html') {
        navigate(`/edittemplate/${selectedTemplateId}`);
      } else {
        // For text format, open a modal
        // Implement modal logic here or trigger a separate edit modal component
        // For simplicity, we'll navigate to the same EditTemplate page
        navigate(`/edittemplate/${selectedTemplateId}`);
      }
    } else {
      toast.error('No Template Selected');
    }
  };

  const handleReplicateChange = (columnIndex, checked) => {
    const newPlaceholders = [...placeholders];
    newPlaceholders[columnIndex].replicate = checked;

    if (checked) {
      const firstValue = newPlaceholders[columnIndex].values[0];
      if (!firstValue.trim()) {
        toast.error('First value is required to replicate!');
        newPlaceholders[columnIndex].replicate = false;
        return;
      }
      newPlaceholders[columnIndex].values = newPlaceholders[columnIndex].values.map(() => firstValue);
    }
    setPlaceholders(newPlaceholders);
  };

  const addPlaceholderColumn = () => {
    setPlaceholders([...placeholders, {placeholder: '', values: [''], replicate: false}]);
  };

  const addValueRow = () => {
    const newPlaceholders = placeholders.map((placeholder) => ({
      ...placeholder,
      values: [...placeholder.values, ''],
    }));
    setPlaceholders(newPlaceholders);
  };

  const deletePlaceholderColumn = (columnIndex) => {
    if (['Subject', 'Recipient Email'].includes(placeholders[columnIndex].placeholder)) {
      toast.error(`Cannot delete required placeholder "${placeholders[columnIndex].placeholder}"`);
      return;
    }
    const newPlaceholders = placeholders.filter((_, index) => index !== columnIndex);
    setPlaceholders(newPlaceholders);
  };

  const validateEmail = (email) => {
    // Simple email regex validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const generateTemplates = () => {
    if (!selectedTemplateData) {
      toast.error('No template content found.');
      return;
    }
    const placeholderNames = placeholders.map((p) => p.placeholder.trim());
    const rowsCount = placeholders[0].values.length;
    const chosenTemplate = templates.find(template => template.id === selectedTemplateId);
    const templateFormat = chosenTemplate ? chosenTemplate.templateFormat : '';

    const requiredPlaceholders = ['Subject', 'Recipient Email'];
    for (let placeholder of requiredPlaceholders) {
      const index = placeholderNames.indexOf(placeholder);
      if (placeholders[index].values.some((v) => v.trim() === '')) {
        toast.error(`Please fill all values for the required placeholder "${placeholder}" before generating templates.`);
        return;
      }
      if (placeholder === 'Recipient Email') {
        const invalidEmails = placeholders[index].values.filter((v) => !validateEmail(v.trim()));
        if (invalidEmails.length > 0) {
          toast.error(`Invalid email format in "${placeholder}".`);
          return;
        }
      }
    }

    for (let i = 0; i < placeholders.length; i++) {
      if (placeholders[i].values.some((v) => v.trim() === '')) {
        toast.error(`Placeholder "${placeholders[i].placeholder}" cannot have empty values.`);
        return;
      }
    }

    const ToBeTemplates = [];
    for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
      const rowValues = placeholders.map((placeholder) => placeholder.values[rowIndex]);
      const isRowFilled = rowValues.every((value) => value.trim() !== '');

      if (!isRowFilled && rowValues.some((value) => value.trim() !== '')) {
        toast.error(`Please fill all values for row ${rowIndex + 1}.`);
        return;
      }

      if (isRowFilled) {
        let newTemplate = selectedTemplateData;
        placeholderNames.forEach((placeholder, columnIndex) => {
          const value = placeholders[columnIndex].values[rowIndex];
          if (value) {
            const regex = new RegExp(escapeRegExp(placeholder), 'g');
            newTemplate = newTemplate.replace(regex, value || '');
          }
        });
        ToBeTemplates.push({
          subject: placeholders.find(p => p.placeholder === 'Subject').values[rowIndex],
          recipientEmail: placeholders.find(p => p.placeholder === 'Recipient Email').values[rowIndex],
          templateData: newTemplate,
          templateFormat: templateFormat,
          attachments: generatedTemplates[rowIndex]?.attachments || [],
        });
      }
    }
    setGeneratedTemplates(ToBeTemplates);
    toast.success('Templates generated successfully!');
  };

  const handleAttachmentChange = (file, index) => {
    const newGeneratedTemplates = [...generatedTemplates];

    // Ensure attachments array exists
    if (!newGeneratedTemplates[index].attachments) {
      newGeneratedTemplates[index].attachments = [];
    }

    // Check if the file is already added to avoid duplicates
    const fileExists = newGeneratedTemplates[index].attachments.some(existingFile => existingFile.name === file.name);

    if (!fileExists) {
      newGeneratedTemplates[index].attachments.push(file);
      setGeneratedTemplates(newGeneratedTemplates);
    } else {
      toast.error('This file has already been added to the attachments.');
    }
  };

  const handleDeleteAttachment = (templateIndex, attachmentIndex) => {
    const newGeneratedTemplates = [...generatedTemplates];

    // Remove the attachment from the specific template
    newGeneratedTemplates[templateIndex].attachments.splice(attachmentIndex, 1);

    setGeneratedTemplates(newGeneratedTemplates);
  };

  const showEditModal = (index) => {
    setCurrentEditIndex(index);

    editForm.setFieldsValue({
      subject: generatedTemplates[index].subject,
      recipientEmail: generatedTemplates[index].recipientEmail,
      userEmail: generatedTemplates[index].userEmail,
      templateData: generatedTemplates[index].templateData,
    });
    setIsEditModalVisible(true);
  };

  const handleEditOk = () => {
    editForm.validateFields().then(values => {
      const updatedTemplates = [...generatedTemplates];
      updatedTemplates[currentEditIndex] = {
        ...updatedTemplates[currentEditIndex],
        ...values,
      };
      setGeneratedTemplates(updatedTemplates);
      setIsEditModalVisible(false);
      toast.success('Template updated successfully!');
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };

  const showViewModal = (template) => {
    setCurrentViewTemplate(template);
    setIsViewModalVisible(true);
  };

  const handleViewOk = () => {
    setIsViewModalVisible(false);
    setCurrentViewTemplate(null);
  };

  const handleViewCancel = () => {
    setIsViewModalVisible(false);
    setCurrentViewTemplate(null);
  };

  const tableColumns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: 300,
    },
    {
      title: 'Recipient Emails',
      dataIndex: 'recipientEmail',
      key: 'recipientEmail',
      width: 200,
    },
    {
      title: 'Template Data',
      dataIndex: 'templateData',
      key: 'templateData',
      render: (text, record, index) => (
        <div className='flex justify-center w-min'>
          <div className={'flex gap-2 justify-center w-min'}
          >
            <Button
              icon={<EyeOutlined/>}
              onClick={() => showViewModal(record)}
              style={{marginBottom: 4}}
            >
            </Button>
            <Button
              icon={<CopyOutlined/>}
              onClick={() => {
                navigator.clipboard.writeText(record.templateData);
                toast.success('Template copied to clipboard!');
              }}
              style={{marginBottom: 4}}
            >
            </Button>
            <Button
              icon={<EditOutlined/>}
              onClick={() => showEditModal(index)}
            >
            </Button>
          </div>
        </div>
      ), // Increased width to accommodate buttons
      width: 100,
    },
    {
      title: 'Attachments',
      dataIndex: 'attachments',
      key: 'attachments',
      render: (attachments, record, index) => (
        <div>
          <Upload
            className='w-min text-center'
            beforeUpload={() => false}
            showUploadList={false}
            onChange={(info) => handleAttachmentChange(info.file, index)}
            multiple
            fileList={attachments.map((file) => ({
              uid: file.uid || file.name,
              name: file.name,
              status: 'done',
              url: file.url || '',
            }))}
          >
            <Button icon={<UploadOutlined/>}>Add Attachments</Button>
          </Upload>
          <div>
            {attachments.map((file, fileIndex) => (
              <div key={fileIndex} className="flex justify-between items-center mt-2">
                <span>{file.name}</span>
                <Button
                  type="text"
                  icon={<DeleteOutlined/>}
                  onClick={() => handleDeleteAttachment(index, fileIndex)}
                />
              </div>
            ))}
          </div>
        </div>
      ),
      width: 150,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record, index) => (
        <div className='flex flex-col gap-2'>
          <Button
            icon={<SendOutlined/>}
            type='primary'
            className={`bg-black text-white ${sendingStatus[index] === 'sending' ? 'bg-blue-600' : ''} ${sendingStatus[index] === 'sent' ? 'bg-green-600' : ''}`}
            onClick={() => handleSendEmail(record, index)}
            style={{marginBottom: 4}}
            disabled={sendingStatus[index] === 'sending'}
          >
            {sendingStatus[index] === 'sending'
              ? 'Sending...'
              : sendingStatus[index] === 'sent'
                ? 'Sent'
                : 'Send'}
          </Button>
        </div>
      ),
      width: 100,
    },
  ];

  const columns = placeholders.map((placeholder, index) => ({
    title: (
      <div>
        <Input
          placeholder='Placeholder Name'
          value={placeholder.placeholder}
          onChange={(e) => handlePlaceholderChange(index, e.target.value)}
          disabled={['Subject', 'Recipient Email'].includes(placeholder.placeholder)}
        />
        <Checkbox
          checked={placeholder.replicate}
          className='mt-2'
          onChange={(e) => handleReplicateChange(index, e.target.checked)}
        >
          Replicate First Value
        </Checkbox>
        {!['Subject', 'Recipient Email'].includes(placeholder.placeholder) && (
          <Button
            type='link'
            icon={<DeleteOutlined/>}
            onClick={() => deletePlaceholderColumn(index)}
            style={{color: 'red', padding: 0}}
          >
            Delete
          </Button>
        )}
      </div>
    ),
    dataIndex: `placeholder-${index}`,
    render: (text, record, rowIndex) => (
      <Input
        placeholder={`Value for ${placeholder.placeholder}`}
        value={placeholder.values[rowIndex] || ''}
        onChange={(e) => handleValueChange(index, rowIndex, e.target.value)}
        disabled={placeholder.replicate}
      />
    ),
  }));

  data = placeholders[0]?.values.map((_, rowIndex) => ({
    key: rowIndex,
    ...placeholders.reduce((acc, placeholder, columnIndex) => {
      acc[`placeholder-${columnIndex}`] = placeholder.values[rowIndex] || '';
      return acc;
    }, {}),
  }));


  return (
    <div className='min-h-screen flex flex-col items-center justify-start py-6 sm:px-6 lg:px-8'>
      <div className='w-full px-3 self-center max-w-8xl'>
        <div className='flex items-center justify-between gap-2 mb-4 '>
          <Select
            value={selectedTemplateId}
            onChange={handleTemplateChange}
            className='h-10'
            style={{width: '100%'}}
          >
            <Select.Option value=''>Select Existing Template</Select.Option>
            {templates?.map((template) => (
              <Select.Option key={template.id} value={template.id}>
                {template.templateType}
              </Select.Option>
            ))}
          </Select>
          <button
            onClick={() => navigate('/template')}
            className='p-2 w-full bg-black text-white rounded-lg '>
            Add New
          </button>
        </div>
        <Collapse>
          <Panel header={<div className='flex items-center justify-between'>
            <p>Selected Template</p>
            <Button
              type='default'
              icon={<EditOutlined/>}
              onClick={() => handleEdit()}
              className='text-black px-2 rounded-md hover:bg-green-600'
            >Edit
            </Button></div>} key="1">
            <div className='selected-template-container'
                 style={{maxWidth: '100%', marginTop: '10px', overflowX: 'hidden'}}>
              {selectedTemplateFormat.toLowerCase() === 'html' ? (
                <div style={{margin: 0, maxHeight: '500px', overflowY: 'auto'}}
                     dangerouslySetInnerHTML={{__html: renderTemplateContent(selectedTemplateData, true)}}/>
              ) : (
                renderTemplateContent(selectedTemplateData, false)
              )}
            </div>
          </Panel>
        </Collapse>
        <Collapse className='mt-3' style={{maxWidth: '100%', overflowX: 'auto'}}>
          <Panel header="Specify Placeholders" key="2">
            <div className='bg-white rounded-lg p-2'>
              <div className='flex justify-between mb-4'>
                <h2 className='text-xl font-semibold'>Specify Placeholders</h2>
                {/* Upload CSV File */}
                <Upload
                  accept=".csv"
                  showUploadList={false}
                  beforeUpload={(file) => {
                    handleCSVUpload(file);
                    return false; // Prevent automatic upload
                  }}
                >
                  <Button type='dashed' icon={<UploadOutlined/>}>
                    Upload CSV File
                  </Button>
                </Upload>
              </div>
              <Table
                className="overflow-x-auto"
                columns={[
                  ...columns,
                  {
                    title: (
                      <Button
                        type='dashed'
                        onClick={addPlaceholderColumn}
                        icon={<PlusOutlined/>}
                        style={{width: '100%'}}
                      >
                        Add Placeholder
                      </Button>
                    ),
                    dataIndex: 'action',
                    key: 'action',
                  },
                ]}
                dataSource={data}
                pagination={false}
                footer={() => (
                  <Button
                    type='dashed'
                    onClick={addValueRow}
                    icon={<PlusOutlined/>}
                    style={{width: '100%'}}
                  >
                    Add Value Row
                  </Button>
                )}
              />

              <button
                type='button'
                className='mt-4 p-2 text-black w-full bg-white border-[1px] border-black rounded-lg'
                onClick={savePlaceholder}
              >
                Save Placeholders
              </button>

              <button
                type='button'
                className='mt-3 w-full bg-black text-white p-2 rounded-lg'
                onClick={generateTemplates}
              >
                Generate Templates
              </button>
            </div>
          </Panel>
        </Collapse>

        <Collapse className='mt-3' key='3'>
          <Panel header="Generated Templates" key="3">
            <div className='bg-white rounded-lg p-2'>
              <div className='flex justify-between items-center mb-4'>
                <h2 className='text-sm font-semibold'>Generated Templates</h2>
                <Button
                  type='primary'
                  icon={<SendOutlined/>}
                  className='bg-black'
                  onClick={handleSampleEmail}
                  loading={buttonState.loading}  // Show loader when sending
                >
                  {buttonState.text} {/* Update button text dynamically */}
                </Button>
              </div>
              <Table
                columns={tableColumns}
                dataSource={generatedTemplates.map((template, index) => ({...template, key: index}))}
                pagination={false}
                scroll={{x: true}}
              />
            </div>
          </Panel>
        </Collapse>
        <div className='self-center w-full'>
          <LogoutButton/>
        </div>
      </div>
      <Modal
        visible={isViewModalVisible}
        onOk={handleViewOk}
        onCancel={handleViewCancel}
        footer={[
          <Button key="close" onClick={handleViewCancel}>
            Close
          </Button>,
        ]}
        width={900}
      >
        <div style={{maxHeight: '500px', overflowY: 'auto'}}>
          <div className='selected-template-container'
               style={{maxWidth: '100%', marginTop: '10px', overflowX: 'hidden'}}>
            {currentViewTemplate?.templateFormat?.toLowerCase() === 'html' ? (
              <div style={{margin: 0, maxHeight: '500px', overflowY: 'auto'}}
                   dangerouslySetInnerHTML={{__html: renderTemplateContent(currentViewTemplate?.templateData, true)}}/>
            ) : (
              renderTemplateContent(currentViewTemplate?.templateData, false)
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title="Edit Template"
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        okText="Save"
      >
        <Form
          form={editForm}
          layout="vertical"
          initialValues={{
            subject: '',
            recipientEmail: '',
            templateData: '',
          }}
        >
          <Form.Item
            label="Subject"
            name="subject"
            rules={[{required: true, message: 'Subject is required'}]}
          >
            <Input disabled/>
          </Form.Item>
          <Form.Item
            label="Recipient Email"
            name="recipientEmail"
            rules={[
              {required: true, message: 'Recipient Email is required'},
              {type: 'email', message: 'Invalid email format'}
            ]}
          >
            <Input disabled/>
          </Form.Item>
          <Form.Item
            label="Template Data"
            name="templateData"
            rules={[{required: true, message: 'Template Data is required'}]}
          >
            {selectedTemplateFormat.toLowerCase() === 'html' ? (
              <AceEditor
                mode="html"
                theme="monokai"
                name="templateDataEditor"
                onChange={newValue => editForm.setFieldsValue({templateData: newValue})}
                value={editForm.getFieldValue('templateData')}
                editorProps={{$blockScrolling: true}}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: false,
                  showLineNumbers: false,
                  tabSize: 1,
                  fontSize: '14px', // Adjust font size as needed
                  lineHeight: 4, // Increased line height
                  fixedWidthGutter: true,            // Enable snippets
                }}
                style={{width: '100%', height: '500px'}} // Adjust height as needed
              />
            ) : (
              <Input.TextArea rows={10}/>
            )}
          </Form.Item>
        </Form>
      </Modal>

    </div>
  );
};

export default Home;
