export const getErrorMessage = (error) => {
  if (error.response && error.response.data) {
    // Handle cases where errors are in an array
    if (Array.isArray(error.response.data.errors) && error.response.data.errors.length > 0) {
      return error.response.data.message;
    }

    // Handle cases where a single error message is provided
    if (error.response.data.message) {
      return error.response.data.message;
    }
    // Fallback if neither 'errors' nor 'message' exists
    return 'Something went wrong. Please try again.';
  } else if (error.request) {
    // Request was made but no response received
    return 'No response from server. Please try again.';
  } else {
    // Something else happened while setting up the request
    return 'An unexpected error occurred. Please try again.';
  }
};
