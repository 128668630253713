import axiosInstance from './axiosConfig'; // Adjust the import based on your file structure
import {getErrorMessage} from '../utils/errorHandler';

// Function to save a template
export const saveTemplate = async (templateDetails) => {
  try {
    const response = await axiosInstance.post('/templates/save', templateDetails, {});
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to save a template
export const savePlaceholders = async (templateId, placeholders) => {
  try {
    const response = await axiosInstance.post(
      `/templates/placeholders/${encodeURIComponent(templateId)}`, // Ensure templateId is correctly encoded
      {placeholders} // Send placeholders as the request body, not as a URL param
    );
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to fetch user templates
export const fetchUserTemplates = async () => {
  try {
    const response = await axiosInstance.get('/templates');
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to send a single email with attachments
export const sendEmail = async (template) => {
  try {
    const formData = new FormData();
    formData.append('templateFormat', template.templateFormat);
    formData.append('subject', template.subject);
    formData.append('recipientEmail', template.recipientEmail);
    formData.append('templateData', template.templateData);
    template.attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    const response = await axiosInstance.post('/email/send', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to send Sample email with attachments
export const sendSampleEmail = async (template, UserEmail) => {
  try {
    const formData = new FormData();
    formData.append('templateFormat', template.templateFormat);
    formData.append('subject', template.subject);
    formData.append('recipientEmail', UserEmail);
    formData.append('templateData', template.templateData);
    template.attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });

    const response = await axiosInstance.post('/email/send', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const fetchTemplateById = async (templateId) => {
  try {
    const response = await axiosInstance.get(`/templates/${encodeURIComponent(templateId)}`);
    return response.data.data; // Adjust based on your API response structure
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Function to update an existing template
export const updateTemplate = async (templateDetails) => {
  try {
    const {id, ...data} = templateDetails; // Extract the ID from the template details
    const response = await axiosInstance.put(`/templates/${encodeURIComponent(id)}`, data);
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};