// src/redux/store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Make sure this path is correct
import {thunk} from 'redux-thunk';
import authReducer from './auth/authReducer'; // Adjust the path as necessary

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Only persist the auth reducer
};

const rootReducer = combineReducers({
  auth: authReducer,
  // Add other reducers here
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store); // Make sure you are exporting this

export { store, persistor }; // Ensure both are exported
