// src/components/MultiStepForm.jsx

import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ErrorMessage, Field, FieldArray, Form, Formik} from 'formik';
import {fetchUserDetails, fetchUserEmail, submitProfileData, uploadResume} from '../network/Profile';
import * as Yup from 'yup';
import {Spin} from 'antd';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useSelector} from "react-redux";
import {LoadingOutlined} from "@ant-design/icons";

// Validation Schemas
const personalDetailsSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits')
    .required('Mobile number is required'),
});

const collegeDetailsSchema = Yup.object({
  collegeName: Yup.string().required('College Name is required'),
  branch: Yup.string().required('Branch is required'),
  batch: Yup.string().required('Batch is required'),
});

const profileSchema = Yup.object({
  github: Yup.string().url('Invalid URL'),
  linkedin: Yup.string().url('Invalid URL').required('LinkedIn URL is required'),
  other: Yup.array()
    .of(Yup.string().url('Invalid URL'))
    .min(1, 'At least one Other URL is required'),
});

const resumeSchema = Yup.object({
  resume: Yup.mixed()
    .required('Resume is required')
    .test(
      'fileFormat',
      'Unsupported Format. Only PDF, DOC, DOCX are allowed.',
      (value) =>
        value &&
        ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(
          value.type
        )
    )
    .test(
      'fileSize',
      'File too large. Maximum size is 5MB.',
      (value) => value && value.size <= 5242880 // 5MB
    ),
});

// Main component
const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('')
  const [formData, setFormData] = useState({
    name: '',
    email: email,
    mobileNo: '',
    collegeName: '',
    branch: '',
    batch: '',
    github: '',
    linkedin: '',
    other: [''],
    resume: null,
  });


  const {token} = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchUser = async () => {
      if (token) {
        try {
          const userEmail = await fetchUserEmail();
          if (userEmail) {
            setEmail(userEmail);
            formData.email = userEmail;
            const userDetails = await fetchUserDetails();
            if (userDetails) {
              setIsLoading(true);
              navigate('/template');
            } else {
              setIsLoading(false)
            }
          } else {
            setIsLoading(false);
            toast('User not found');
          }
        } catch (error) {
          console.error('Failed to fetch user details:', error);
          toast.error(error.message);
           setIsLoading(false);
        }
      } else {
        toast.error('User Not Found!');
         setIsLoading(false);
      }
    };
    fetchUser();
  }, [token, navigate]);

  // Handle next step
  const handleNextStep = (values) => {
    setFormData((prev) => ({...prev, ...values}));
    setCurrentStep((prevStep) => prevStep + 1);
  };

  // Handle previous step
  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  // Handle final form submission
  const handleSubmit = async (values) => {
    const finalData = {...formData, ...values};
    setIsSubmitting(true);

    if (finalData.resume) {
      const uploadResponse = await uploadResume(finalData.resume);
      if (!uploadResponse.success) {
        toast.error(`Resume upload failed: ${uploadResponse.error}`);
        setIsSubmitting(false);
        return;
      }
      finalData.resume = [uploadResponse.data];
    }

    finalData.other = finalData.other.filter((url) => url.trim() !== '');

    const profileResponse = await submitProfileData(finalData);
    if (profileResponse.success) {
      toast.success('Profile submitted successfully!');
      setTimeout(() => navigate('/template'), 2000);
    } else {
      toast.error(`Profile submission failed: ${profileResponse.error}`);
      setIsSubmitting(false);
    }

  };

  // Function to render steps
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Formik
            initialValues={formData}
            validationSchema={personalDetailsSchema}
            onSubmit={handleNextStep}
          >
            {() => (
              <Form className='space-y-6 p-4 border-none shadow-none'>
                <h2 className='text-2xl font-semibold text-gray-800 text-center'>
                  Personal Details
                </h2>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    Name
                  </label>
                  <Field
                    name='name'
                    placeholder='Enter your Full Name'
                    className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    Email
                  </label>
                  <Field
                    name='email'
                    type='email'
                    disabled={!!email}
                    placeholder='Enter your Email ID'
                    className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                  />
                  <ErrorMessage
                    name='email'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    Mobile
                  </label>
                  <Field
                    name='mobile'
                    placeholder='Enter your 10 Digit Mobile Number'
                    className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                  />
                  <ErrorMessage
                    name='mobile'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div className='flex justify-end'>
                  <button
                    type='submit'
                    className='px-4 py-2 bg-black text-white rounded-lg hover hover:translate-y-[2px] transition-all focus:outline-none focus:ring-2 focus:ring-blue-500'
                    key='next-button-personal'
                  >
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        );
      case 2:
        return (
          <Formik
            initialValues={formData}
            validationSchema={collegeDetailsSchema}
            onSubmit={handleNextStep}
          >
            {() => (
              <Form className='space-y-6 p-4'>
                <h2 className='text-2xl font-semibold text-gray-800 text-center'>
                  College Details
                </h2>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    College Name
                  </label>
                  <Field
                    name='collegeName'
                    placeholder='Enter your College Name'
                    className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                  />
                  <ErrorMessage
                    name='collegeName'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    Branch
                  </label>
                  <Field
                    name='branch'
                    placeholder='Enter your Branch'
                    className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                  />
                  <ErrorMessage
                    name='branch'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    Batch
                  </label>
                  <Field
                    name='batch'
                    placeholder='Enter your Batch (e.g., 2021-2025)'
                    className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                  />
                  <ErrorMessage
                    name='batch'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div className='flex justify-end gap-2'>
                  <button
                    type='button'
                    className='px-4 py-2 text-sm bg-white border-[1px] border-black rounded-lg text-gray-700 hover:bg-black transition-all focus:outline-none focus:ring-2 focus:ring-gray-500'
                    onClick={handlePrevStep}
                    key='prev-button-college'
                  >
                    Previous
                  </button>

                  <button
                    type='submit'
                    className='px-4 py-2 text-sm bg-black border-[1px] text-white rounded-lg hover:bg-white hover:border-[1px] hover:border-black hover:text-black transition-all focus:outline-none focus:ring-2 focus:ring-blue-500'
                    key='next-button-college'
                  >
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        );

      case 3:
        return (
          <Formik
            initialValues={formData}
            validationSchema={profileSchema}
            onSubmit={handleNextStep}
          >
            {({values, errors, touched}) => (
              <Form className='space-y-6 p-4'>
                <h2 className='text-2xl font-semibold text-gray-800 text-center'>
                  Profiles
                </h2>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    *LinkedIn
                  </label>
                  <Field
                    name='linkedin'
                    type='url'
                    placeholder='Enter your LinkedIn URL'
                    className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                  />
                  <ErrorMessage
                    name='linkedin'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    GitHub
                  </label>
                  <Field
                    name='github'
                    type='url'
                    placeholder='Enter your GitHub URL'
                    className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                  />
                  <ErrorMessage
                    name='github'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    Other URLs
                  </label>
                  <FieldArray name='other'>
                    {({push, remove}) => (
                      <div>
                        {values.other && values.other.length > 0 && values.other.map((url, index) => (
                          <div key={index} className='flex items-center mb-2'>
                            <Field
                              name={`other.${index}`}
                              type='url'
                              placeholder='Enter another profile URL'
                              className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                            />
                            {index > 0 && (
                              <button
                                type='button'
                                className='ml-2 text-red-500 hover:text-red-700 focus:outline-none'
                                onClick={() => remove(index)}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        ))}
                        <button
                          type='button'
                          className='mt-2 px-4 py-2 bg-white text-black  text-sm border-[1px] border-black rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500'
                          onClick={() => push('')}
                        >
                          Add Another
                        </button>
                        {errors.other && touched.other && typeof errors.other === 'string' && (
                          <div className='text-red-500 text-sm mt-1'>{errors.other}</div>
                        )}
                        {errors.other && Array.isArray(errors.other) && errors.other.map((error, index) => (
                          error && (
                            <div key={index} className='text-red-500 text-sm mt-1'>
                              {`Other URL ${index + 1}: ${error}`}
                            </div>
                          )
                        ))}
                      </div>
                    )}
                  </FieldArray>
                </div>

                <div className='flex justify-end gap-2'>
                  <button
                    type='button'
                    className='px-4 py-2 text-sm bg-white border-[1px] border-black rounded-lg text-gray-700 hover:bg-black transition-all focus:outline-none focus:ring-2 focus:ring-gray-500'
                    onClick={handlePrevStep}
                    key='prev-button-profile'
                  >
                    Previous
                  </button>

                  <button
                    type='submit'
                    className='px-4 py-2 text-sm bg-black border-[1px] text-white rounded-lg hover:bg-white hover:border-[1px] hover:border-black hover:text-black transition-all focus:outline-none focus:ring-2 focus:ring-blue-500'
                    key='next-button-profile'
                  >
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        );

      case 4:
        return (
          <Formik
            initialValues={formData}
            validationSchema={resumeSchema}
            onSubmit={handleSubmit}
          >
            {({setFieldValue}) => (
              <Form className='space-y-6 p-4'>
                <h2 className='text-2xl font-semibold text-gray-800 text-center'>
                  Upload Resume
                </h2>

                <div>
                  <label className='block text-sm font-medium text-gray-500 mb-2'>
                    Resume (PDF or DOC/DOCX)
                  </label>
                  <input
                    name='resume'
                    type='file'
                    accept='.pdf,.doc,.docx'
                    onChange={(e) => setFieldValue('resume', e.target.files[0])}
                    className='mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500'
                  />
                  <ErrorMessage
                    name='resume'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                <div className='flex justify-end gap-2'>
                  <button
                    type='button'
                    className='px-4 py-2 text-sm bg-white border-[1px] border-black rounded-lg text-gray-700 hover:bg-black transition-all focus:outline-none focus:ring-2 focus:ring-gray-500'
                    onClick={handlePrevStep}
                    key='prev-button-resume'
                  >
                    Previous
                  </button>

                  <button
                    type='submit'
                    disabled={isSubmitting}
                    className={`px-4 py-2 text-sm bg-black border-[1px] text-white rounded-lg hover:bg-white hover:border-[1px] hover:border-black hover:text-black transition-all focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                      isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        );

      default:
        return null;
    }
  };

  return (
    <div className=''>
      {isLoading ? (<div className='flex self-center h-screen justify-center items-center'>
        <Spin indicator={<LoadingOutlined spin/>}/>
      </div>) : (
        <div className='min-h-screen bg-white flex flex-col items-center justify-center py-12 px-0 sm:px-2 lg:px-8'>
          <div className='w-full max-w-md bg-white rounded-lg'>{renderStep()}</div>
        </div>)}

    </div>
  );
};

export default MultiStepForm;
