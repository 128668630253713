import React, {useEffect, useState} from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import {useNavigate, useParams} from "react-router-dom";
import {fetchTemplateById, updateTemplate} from "../network/Template";
import {toast} from "react-toastify";
import '../assets/Styles/EditTemplate.css';
import {CloseOutlined, LoadingOutlined} from "@ant-design/icons";
import {Button, Form, Input, Spin} from "antd";

const EditTemplate = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  // Fetch the template details on component mount
  useEffect(() => {
    const loadTemplate = async () => {
      setIsLoading(true);
      try {
        const fetchedTemplate = await fetchTemplateById(id);
        setTemplate(fetchedTemplate);
      } catch (error) {
        toast.error('Failed to fetch template.');
      } finally {
        setIsLoading(false);
      }
    };
    loadTemplate();
  }, [id]);

  const handleCancel = async () => {
    navigate('/generate');
  }

  const handleSave = async () => {
    if (!template.templateType || !template.templateFormat || !template.templateData) {
      toast.error('All fields are required.');
      return;
    }

    setIsSaving(true);

    try {
      await updateTemplate(template);
      toast.success('Template updated successfully.');
      navigate('/');
    } catch (error) {
      toast.error('Failed to update the template. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  // Function to handle changes in the editor or input fields
  const handleChange = (field, value) => {
    setTemplate({...template, [field]: value});
  };

  // Function to render preview based on format
  const renderPreview = (format, content) => {
    if (!content) return <p>No template data available.</p>;

    if (format.toLowerCase() === 'html') {
      return content; // Return sanitized HTML string
    } else if (format.toLowerCase() === 'text') {
      return (
        <pre className="text-preview">
          {content}
        </pre>
      );
    } else {
      return <p>Unsupported format.</p>;
    }
  };

  if (isLoading) {
    return (
      <div className='flex justify-center items-center min-h-screen'>
        <Spin indicator={<LoadingOutlined spin/>}/>
      </div>
    );
  }

  if (!template) {
    return (
      <div className='flex justify-center items-center min-h-screen'>
        <p>Template not found.</p>
      </div>
    );
  }

  return (
    <div className='bg-white rounded-lg w-full flex flex-col'>
      <Form layout='vertical' className=''>
        <div className='edit-title lg:px-4 px-2 mb-0 h-min flex items-center justify-between rounded-lg mt-2'>
          <Form.Item className='template-title'>
            <Input
              value={template.templateType}
              onChange={(e) => handleChange('templateType', e.target.value)}
              placeholder='Enter Title'
            />
          </Form.Item>
          <div className='flex gap-2'>
            <Form.Item className='form-button items-center justify-center'>
              <Button
                type='primary'
                onClick={handleSave}
                loading={isSaving}
                className='w-full bg-black hover:bg-blue-600'
              >
                Save Changes
              </Button>
            </Form.Item>
            <Form.Item className='form-button items-center justify-center'>
              <button
                onClick={handleCancel}
                className='w-full px-2 py-1 rounded-lg text-black bg-gray-100 hover:bg-gray-200'
              >
                {<CloseOutlined/>}
              </button>
            </Form.Item>
          </div>
        </div>

        <Form.Item required className=''>
          {template.templateFormat.toLowerCase() === 'html' ? (
            <div
              className="flex w-full px-2 pb-2 pt-0 justify-content-between lg:flex-row flex-col rounded-lg relative"
            >
              {/* Code Editor */}
              <div className='responsive-editor-container bg-gray-5'>
                <AceEditor
                  mode='html'
                  theme='monokai'
                  id='htmlEditor'
                  name='htmlEditor'
                  onChange={(newValue) => handleChange('templateData', newValue)}
                  value={template.templateData}
                  editorProps={{$blockScrolling: false}}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    showLineNumbers: false,
                    tabSize: 1,
                    fontSize: '14px', // Adjust font size as needed
                    lineHeight: 4, // Increased line height
                    fixedWidthGutter: true, // Fixed width for the gutter
                  }}
                  style={{
                    height: '92vh',
                    width: '700px',
                    border: '1px solid #ccc',
                    borderRadius: '0.5rem',
                  }}
                />
              </div>

              {/* HTML Preview */}
              <div
                className="preview md:p-4 p-2 md:pt-0 pt-2 h-[92vh]  overflow-scroll overflow-y-scroll overflow-x-hidden"
              >
                <div
                  className='preview-container overflow-y-hidden'
                  dangerouslySetInnerHTML={{__html: renderPreview(template.templateFormat, template.templateData)}}
                />
              </div>
            </div>
          ) : (
            <div className='md:px-6 px-2 py-2'>
              <Input.TextArea
                value={template.templateData}
                onChange={(e) => handleChange('templateData', e.target.value)}
                rows={15}
                placeholder='Enter template content...'
                className='edit-text-template'
              />
            </div>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditTemplate;
