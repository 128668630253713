import axiosInstance from './axiosConfig'; // Adjust the import based on your file structure
import {getErrorMessage} from '../utils/errorHandler';

export const login = async (email, password) => {
  try {
    const response = await axiosInstance.post('/auth/login', {email, password});
    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const signup = async (email, password) => {
  try {
    const response = await axiosInstance.post('/auth/signup', {email, password});
    return response.data;
  } catch (error) {
    // Process and throw a standardized error message
    throw new Error(getErrorMessage(error));
  }
};