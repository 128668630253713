import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {fetchUserTemplates, saveTemplate} from '../network/Template';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {toast} from 'react-toastify';

const ChooseTemplate = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [templateDetails, setTemplateDetails] = useState({
    templateType: '',
    templateFormat: '',
    templateData: '',
    templateId: ''
  });
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state for saving
  const [isFetching, setIsFetching] = useState(false); // Loading state for fetching templates
  const navigate = useNavigate();

  useEffect(() => {
    const loadTemplates = async () => {
      setIsFetching(true);
      try {
        const userTemplates = await fetchUserTemplates();
        setTemplates(userTemplates);
      } catch (error) {
        toast.error('Failed to fetch templates.');
      } finally {
        setIsFetching(false);
      }
    };

    loadTemplates();
  }, []);

  const handleTemplateChange = (e) => {
    const chosenTemplateId = e.target.value;
    const chosenTemplate = templates.find((template) => template.id === chosenTemplateId);
    if (chosenTemplate) {
      setSelectedTemplateId(chosenTemplate.id);
      setTemplateDetails({
        templateType: chosenTemplate.templateType,
        templateFormat: chosenTemplate.templateFormat,
        templateData: chosenTemplate.templateData,
        templateId: chosenTemplate.id,
      });
    } else {
      // If no template is selected, clear the templateDetails
      setTemplateDetails({
        templateType: '',
        templateFormat: '',
        templateData: '',
        templateId: ''
      });
    }
  };

  const handleSave = async (addMore) => {
    // Validate required fields
    if (!templateDetails.templateType || !templateDetails.templateFormat || !templateDetails.templateData) {
      setError('All fields are required.'); // Set error message if fields are not filled
      return;
    }
    setError(''); // Clear error message if validation passes

    setIsLoading(true); // Set loading state to true

    try {
      await saveTemplate(templateDetails);
      toast.success('Template saved successfully.');
      if (addMore) {
        // Logic for saving and allowing the user to add more templates
        setTemplateDetails({templateType: '', templateFormat: '', templateData: ''}); // Clear fields for new template entry
        setIsCreatingNew(true); // Keep in the create new mode
      } else {
        // Logic for saving and moving to the next step
        navigate('/generate', {state: {templateContent: templateDetails.templateData}}); // Pass templateData
      }
    } catch (error) {
      setError('Failed to save the template. Please try again.');
    } finally {
      setIsLoading(false); // Set loading state to false when done
    }
  };

  const handleNext = () => {
    // Navigate to home with the selected template
    navigate('/generate', {
      state: {
        sentTemplateId: selectedTemplateId,
        sentTemplateContent: templateDetails.templateData
      }
    });
  };

  const handleCreateNew = () => {
    setIsCreatingNew(true);
    setSelectedTemplateId('');
    setTemplateDetails({
      templateType: '',
      templateFormat: '',
      templateData: '',
    });
  };

  const handleCancelCreateNew = () => {
    setIsCreatingNew(false);
    setTemplateDetails({
      templateType: '',
      templateFormat: '',
      templateData: '',
    });
  };

  const renderTemplateContent = (text, isHTML = false) => {
    if (!text) return 'No template data available.';

    if (isHTML) {
      // Sanitize the HTML content to prevent XSS attacks
      return (text);
    } else {
      return text;
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen'>
      <div className='bg-white p-4 rounded-lg w-full max-w-lg '>
        <h2 className='text-2xl text-center font-semibold mb-6'>Choose a Template</h2>

        {!isCreatingNew && (
          <>
            {isFetching ? (
              <div className='flex justify-center items-center'>
                <Spin indicator={<LoadingOutlined spin/>}/>
              </div>
            ) : (
              <>
                {templates.length > 0 ? (
                  <>
                    {/* Select Existing Template */}
                    <div className='mb-4'>
                      <h3 className='text-lg text-center font-medium mb-2'>Select an existing template:</h3>
                      <select
                        value={selectedTemplateId}
                        onChange={handleTemplateChange}
                        className='w-full mt-2 p-2 border rounded-lg'
                      >
                        <option value=''>Select Template</option>
                        {templates.map((template) => (
                          <option key={template.id} value={template.id}>
                            {template.templateType}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Preview of Selected Template and Next Button */}
                    {selectedTemplateId && (
                      <div className='mb-4 mt-6'>

                        {/* Dropdown for Template Format */}
                        <label htmlFor='templateFormat' className='block mt-4 text-sm font-medium text-gray-700'>
                          Template Format
                        </label>
                        <select
                          id='templateFormat'
                          value={templateDetails.templateFormat}
                          onChange={(e) =>
                            setTemplateDetails({...templateDetails, templateFormat: e.target.value})
                          }
                          className='w-full mt-1 p-2 cursor-not-allowed border rounded-md bg-white' // Disabled-like appearance
                          disabled
                        >
                          <option value=''>Select Format</option>
                          <option value='text'>Text</option>
                          <option value='html'>HTML</option>
                        </select>

                        {/* Input for Template Content */}
                        <label htmlFor='templateData' className='block mt-4 text-sm font-medium text-gray-700'>
                          Template Content
                        </label>
                        {templateDetails.templateFormat.toLowerCase() === 'html' ? (
                          <div
                            id='templateData'
                            className='w-full mt-1 p-2 border rounded-md bg-white'
                            style={{maxHeight: '200px', overflowY: 'auto'}}
                            dangerouslySetInnerHTML={{__html: renderTemplateContent(templateDetails.templateData, true)}}
                          />
                        ) : (
                          <pre
                            id='templateData'
                            className='w-full mt-1 p-2 border rounded-md bg-white'
                            style={{
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'break-word',
                              maxHeight: '200px',
                              overflowY: 'auto'
                            }}
                          >
    {renderTemplateContent(templateDetails.templateData, false)}
  </pre>
                        )}

                        {error && <p className='text-red-500 mt-2'>{error}</p>} {/* Show error message */}

                        <button
                          onClick={handleNext}
                          className='w-full bg-gray-200 text-black py-2 px-4 rounded-md hover:bg-gray-100 mt-2'
                        >
                          Next
                        </button>
                      </div>
                    )}

                    {/* "OR" Separator */}
                    <div className='flex items-center justify-center my-4'>
                      <hr className='w-1/4 border-gray-300'/>
                      <span className='mx-2 text-gray-500'>OR</span>
                      <hr className='w-1/4 border-gray-300'/>
                    </div>
                  </>
                ) : null}

                {/* Create New Template Button */}
                <button
                  onClick={handleCreateNew}
                  className='w-full bg-black text-white py-2 px-4 rounded-md hover:bg-blue-600'
                >
                  Create New Template
                </button>
              </>
            )}

          </>
        )}

        {/* Create New Template Form */}
        {isCreatingNew && (
          <>
            <div className='mb-4 mt-6'>
              <h3 className='text-lg mb-4 font-medium'>New Template Details</h3>

              {/* Dropdown for Template Type */}
              <label htmlFor='templateType' className='block mt-2 text-sm font-medium text-gray-700'>
                Template Title
              </label>
              <input
                type='text'
                id='templateType'
                value={templateDetails.templateType}
                onChange={(e) =>
                  setTemplateDetails({...templateDetails, templateType: e.target.value})
                }
                className='w-full mt-1 p-2 border rounded-md'
                placeholder='Enter Template Title'
              />

              {/* Dropdown for Template Format */}
              <label htmlFor='templateFormat' className='block mt-4 text-sm font-medium text-gray-700'>
                Template Format
              </label>
              <select
                id='templateFormat'
                value={templateDetails.templateFormat}
                onChange={(e) =>
                  setTemplateDetails({...templateDetails, templateFormat: e.target.value})
                }
                className='w-full mt-1 p-2 border rounded-md'
              >
                <option value=''>Select Format</option>
                <option value='text'>Text</option>
                <option value='html'>HTML</option>
              </select>

              {/* Input for Template Content */}
              <label htmlFor='templateData' className='block mt-4 text-sm font-medium text-gray-700'>
                Template Content
              </label>
              <textarea
                id='templateData'
                value={templateDetails.templateData}
                onChange={(e) =>
                  setTemplateDetails({...templateDetails, templateData: e.target.value})
                }
                className='w-full mt-1 p-2 border rounded-md'
                rows='4'
                placeholder='Enter template content...'
              />

              {error && <p className='text-red-500 mt-2'>{error}</p>} {/* Show error message */}
            </div>

            <div className='flex flex-col justify-between gap-2'>
              <button
                onClick={() => handleSave(true)} // Save and Add More
                className='w-full bg-white border border-black text-sm text-black py-2 px-4 rounded-lg hover:bg-blue-600'
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? 'Saving...' : 'Save and Add More'}
              </button>
              <button
                onClick={() => handleSave(false)} // Save and Next
                className='w-full bg-black text-sm text-white py-2 px-4 rounded-lg hover:bg-blue-600'
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? 'Saving...' : 'Save and Next'}
              </button>
            </div>

            {/* Cancel Button */}
            <button
              onClick={handleCancelCreateNew}
              className='w-full bg-gray-200 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-100 mt-4'
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ChooseTemplate;
